import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Formulaire = () => {

    const redirection = useNavigate();

    const [donnee, setDonnee] = useState({
        userEmail: '',
        userLastname: '',
        userFirstname: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setDonnee({
            ...donnee,
            [name]: value
        });
    };

    const isEmailValid = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isEmailValid(donnee.userEmail)) {
            console.log('Formulaire valide :', donnee);
            redirection('/confirmation');
        } else {
            console.log('Adresse email invalide');
        }
    };

    return (
        <div>
            <h1>Formulaire de candidature</h1>
            <form className='contact' onSubmit={handleSubmit} action="/home">
                <div className='form left'>
                    <input
                        type='email'
                        placeholder='Adresse Email'
                        name='userEmail'
                        value={donnee.userEmail}
                        onChange={handleChange}
                        required
                    />
                    <input
                        type='text'
                        placeholder='Nom'
                        name='userLastname'
                        value={donnee.userLastname}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='form right'>
                    <input
                        type='text'
                        placeholder='Prénom'
                        name='userFirstname'
                        value={donnee.userFirstname}
                        onChange={handleChange}
                        required
                    />
                    <textarea
                        placeholder='Message'
                        name='message'
                        value={donnee.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <input type='submit' value='Envoyer' id='input-submit' />
            </form>
        </div>
    );
};

export default Formulaire;
