import React, { useState } from 'react';

const Collapse = ({ title, description }) => {
    const [open, setOpen] = useState(false);

    const handleCollapse = () => {
        setOpen(!open);
    }

    return (
        <ul className='collapse'>
            <li>
                <div className='enteteCollapse' onClick={handleCollapse}>
                    <p>{title}</p>
                    <p className={open ? 'arrowUp' : 'arrowDown'}>&#10094;</p>
                </div>
                {open && (
                    <div className='descriptionCollapse'>
                        {Array.isArray(description) ? (
                            <ul>
                                {description.map((item, index) => (
                                    <li key={index}>{item}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{description}</p>
                        )}
                    </div>
                )}
            </li>
        </ul>
    );
};

export default Collapse;
