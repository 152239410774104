import ListePatissiers from "@/Assets/Api/Patissiers.json";


const GetAllPatissiers = () => {
    return ListePatissiers;
}


const GetOnePatissier = () => {
    // Récupérer l'ID de l'URL
    const idFromURL = window.location.pathname.split('/').filter(Boolean).pop();
    return ListePatissiers.find(patissier => patissier.id === idFromURL);
}

export const PatissierService = {
    GetAllPatissiers, GetOnePatissier
}

export default PatissierService;