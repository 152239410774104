import React from 'react';
import PatissierService from '@/_Services/Service.patissier';
import Collapse from '@/Components/Collapse/Collapse';
import { Slide } from 'react-slideshow-image';
import { Fade } from "react-awesome-reveal";



const Patissiers = PatissierService.GetAllPatissiers();

const Candidat = () => {
    const ListePreparations =
        [

            { index: 1, image: 'https://airzk.fr/tmp/preparation/prepa01.jpg', nom: 'Découvrez nos candidats' },
            { index: 2, image: 'https://airzk.fr/tmp/preparation/prepa02.jpg', nom: '...A vos marques' },
            { index: 3, image: 'https://airzk.fr/tmp/preparation/prepa03.jpg', nom: 'Prêts' },
            { index: 4, image: 'https://airzk.fr/tmp/preparation/prepa04.jpg', nom: 'Patissez' }
        ]

    return (
        <section className='sectionCandidat'>
            <div className='slideContainer'>
                <Slide>
                    {ListePreparations.map((preparation) => (
                        <div className="each-slide-effect" key={preparation.index}>
                            <div key={preparation.index} style={{ 'backgroundImage': `url(${preparation.image})` }}>
                                <h3>{preparation.nom} </h3>
                            </div>
                        </div>
                    ))}
                </Slide>
            </div>

            {Patissiers.map((patissier) => (
                <Fade>
                    <div className='patissier'>

                        <div className='photo'>
                            <img src={patissier.photo} alt='le patissier' /></div>

                        <div className='description'>
                            <Collapse title={patissier.prenom} description={patissier.description} />
                        </div>
                    </div>
                </Fade>
            ))
            }
        </section>
    );
};

export default Candidat;