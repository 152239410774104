import React from 'react';

import Banner from "@/Components/Banner/Banner";
import Profil from '@/Components/Profil/Profil';
import { Fade, Slide } from "react-awesome-reveal";

import Cyril from "@/Assets/Images/Pages/Equipe/LMP_Cyril-Lignac_transparent.png"
import Mercotte from "@/Assets/Images/Pages/Equipe/LMP_Mercotte_transparent.png"

import BannerImg from "@/Assets/Images/Pages/Home/HeroHome.webp";
import EquipeLMP from "@/Assets/Images/Pages/Equipe/EquipeLMP1.jpg";



const Home = () => {
    return (
        <section className='sectionHome'>
            <div>
                <Banner image={BannerImg} />
            </div>
            <div>
                <Slide cascade direction="right">
                    <h1>Une Equipe de choc... olat</h1>
                    <Profil photo={Cyril} title="Cyril Lignac" description="Lorem est ex aliqua consectetur aliquip elit irure consequat ullamco do. Ad excepteur amet anim ullamco in fugiat enim ut. Eu elit esse eu mollit est deserunt nostrud minim aliquip culpa occaecat adipisicing irure id. Sunt nulla nostrud do cillum ea ullamco velit ut sint." />
                    <Profil photo={Mercotte} title="Mercotte" description="Minim ad enim eu consequat culpa. Eu irure fugiat anim non amet. Officia id exercitation amet veniam amet incididunt sint laboris sit officia occaecat." />
                </Slide>
            </div>
            <div>
                <Fade>
                    <Banner image={EquipeLMP} title="Rendez-vous sur M6 tous les Samedi à 19h00" />
                </Fade>
            </div>

        </section>
    );
};

export default Home;