import React from 'react';
import Banner from '@/Components/Banner/Banner';
import Formulaire from '@/Components/Formulaire/Formulaire';
import BannerImg from '@/Assets/Images/Pages/Contact/HeroContact.jpg';


const Contact = () => {
    return (
        <section className='sectionContact'>
            <Banner image={BannerImg} />
            <Formulaire />
        </section>
    );
};

export default Contact;