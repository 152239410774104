import React from 'react';
import { NavLink } from 'react-router-dom';


const Error404 = () => {
    return (
        <div className='error'>

            <h1>404</h1>

            <span>Oups! La page que vous demandez n'existe pas.</span>

            <NavLink to="/home" className={"navlink"}>
                <p>Retourner sur la page d’accueil</p>
            </NavLink>



        </div >
    );
};

export default Error404;