import React from 'react';

const Tag = ({ tags }) => {
    return (
        <ul className='tags'>
            {tags.map((item) => (
                < li className='tag' >{item}</li>
            ))
            }
        </ul >
    )
};

export default Tag;