import React from 'react';
import { NavLink } from 'react-router-dom';


const Error404 = () => {
    return (
        <div className='error'>

            <h3>Message envoyé</h3>

            <span>Nous reviendrons bientôt vers vous</span>

            <NavLink to="/home" className={"navlink"}>
                <p>Retourner sur la page d’accueil</p>
            </NavLink>



        </div >
    );
};

export default Error404;