import React from 'react';
import recetteService from '@/_Services/Service.recette';
import Rating from '@/Components/Rating/Rating';
import Tag from '@/Components/Tag/Tag';
import Collapse from '@/Components/Collapse/Collapse'
import { Slide } from 'react-slideshow-image';
import { JackInTheBox } from "react-awesome-reveal";

const recette = () => {

    const ListeGateaux =
        [
            { index: 1, image: 'https://airzk.fr/tmp/gateaux/gateau001.jpg', nom: 'Coeur de fruits' },
            { index: 2, image: 'https://airzk.fr/tmp/gateaux/gateau002.jpg', nom: 'Macarons' },
            { index: 3, image: 'https://airzk.fr/tmp/gateaux/gateau003.jpg', nom: 'Entremet' },
            { index: 4, image: 'https://airzk.fr/tmp/gateaux/gateau004.jpg', nom: 'Milles fleurs' },
            { index: 5, image: 'https://airzk.fr/tmp/gateaux/gateau005.jpg', nom: 'Cremeux chocolat' }
        ]

    const recettes = recetteService.GetAllRecettes();
    console.log(recettes)
    return (
        <section className='sectionRecette'>
            <div className='slideRecette'>
                <Slide>
                    {ListeGateaux.map((gateau) => (
                        <div className="recette-slide-effect" key={gateau.index}>
                            <div key={gateau.index} style={{ 'backgroundImage': `url(${gateau.image})` }}>
                                <h3>{gateau.nom} </h3>
                            </div>
                        </div>
                    ))}
                </Slide>
            </div>

            <div className='properties'>
                {recettes.map((recette) => (
                    <JackInTheBox>
                        <div className='infosPatisserie'>
                            <h2>{recette.title}</h2> <Rating rating={recette.difficulte} />
                            <img src={recette.photo} alt="une recette de gâteau"></img>
                            <div className='infosDescriptions'>
                                <Tag tags={recette.ingredients} />
                                <Collapse title='Description' description={recette.description} />
                                <Collapse title='équipements' description={recette.equipments} />
                            </div>
                        </div>
                    </JackInTheBox>
                ))}
            </div>

        </section >
    );
};

export default recette;