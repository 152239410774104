import ListeRecettes from "@/Assets/Api/Recettes.json";


const GetAllRecettes = () => {
    return ListeRecettes;
}


const GetOneRecette = () => {
    // Récupérer l'ID de l'URL
    const idFromURL = window.location.pathname.split('/').filter(Boolean).pop();
    return ListeRecettes.find(recette => recette.id === idFromURL);
}

export const RecetteService = {
    GetAllRecettes, GetOneRecette
}

export default RecetteService;