import React, { useState, useEffect } from 'react';

const Rating = ({ rating }) => {
    const [rate, setRate] = useState(0);

    useEffect(() => {
        setRate(rating);
    }, [rating]);

    return (<div>

        <ul className="rating">
            <span>Difficulté :</span>
            {Array.from({ length: 5 }, (_, index) => (
                <li
                    key={index}
                    className={index < rate ? 'colorstar' : 'whitestar'}
                >
                    &#9733;
                </li>
            ))}
        </ul>
    </div>
    );
};

export default Rating;
