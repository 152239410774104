import React, { useState } from 'react';

const Profil = ({ photo, title, description }) => {
    const [open, setOpen] = useState(false);

    const handleFlip = () => {
        setOpen(!open);
    }

    return (
        <div className='profilContainer'>
            <div className='photo' onClick={handleFlip}>
                <img src={photo} alt="profil" />
            </div>
            <div className='profil'>
                <div className='enteteProfil' onClick={handleFlip}>
                    <h2>{title}</h2>
                </div>
                <div className='descriptionProfil'>
                    <p>{description}</p>
                </div>
            </div>
        </div>
    );
};

export default Profil;
